@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: hidden;
}

body {
    @apply overflow-x-hidden font-rajdhani;
    background: linear-gradient(
        /* rgb(134, 41, 142), */ /* rgb(78, 26, 120), */ 
        rgb(161, 13, 161),
        /*rgb(23, 23, 23),*/
        rgb(7, 7, 84),
        rgb(2, 0, 15)
    ) !important;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 0%;
}

progress::-webkit-progress-bar {
    background: transparent;
}

#waving-alien {
    animation: translate-up-down 2.5s infinite;
}

@keyframes translate-up-down {
    0% {
        translate: 0px -5px;
    }

    50% {
        translate: 0px 0px;
    }

    100% {
        translate: 0px -5px;
    }
}

#blink {
    animation: blink 1.5s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}
